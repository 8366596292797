// import { dev } from '$app/environment';
// import * as Sentry from "@sentry/sveltekit";

// Sentry.init(
//   {
//     dsn: 'https://2a82e9166dc34d6937e884171ab629e8@o4507068185903104.ingest.de.sentry.io/4508093418569808',
//     tracesSampleRate: 1.0,

//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 0.01,

//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,
//     enabled: !dev
//   }
// )
// const myErrorHandler = ({ error, event }: any) => {
//   console.error("An error occurred on the client side:", error, event);
// };

// export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)

export const handleError = console.log;